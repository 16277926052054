body {
  font-size: 15px;
}

/* font and text */
.p-subtitle {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.03em;
}

.p-line {
  text-decoration-line: line-through;
}

.p-bold {
  font-weight: bold;
}

.p-bold {
  font-weight: bold;
}

.p-smaller {
  font-size: .75rem;
}

.p-small {
  font-size: small;
}

.p-medium {
  font-size: medium;
}

.p-upper {
  text-transform: uppercase;
}

.p-justify{
  text-align: justify !important;
}

.txtBlue {
  color: rgb(0, 38, 189)
}

.txtGreen {
  color: rgb(0, 38, 189)
}

.txtGray {
  color: #666666
}

#logoTxt {
  position: relative;
  /* border: 1px solid black; */
  width: 30%;
  height: 100px;
  margin: 10px auto;
  font-weight: 500;
}

.mi {
  position: absolute;
  top: 10px;
  font-size: 1rem;
  text-align: left;
  margin: -5px;
  padding: -5px;
}

.seguro {
  position: absolute;
  top: 20px;
  font-size: 2rem;
  text-align: left;
  margin: -5px;
  padding: -5px;
}

.de {
  position: absolute;
  top: 20px;
  left: 120px;
  font-size: 1rem;
  text-align: left;
  margin: -5px;
  padding: -5px;
}

.auto {
  position: absolute;
  top: 50px;
  left: 60px;
  font-size: 2rem;
  text-align: right;
  margin: -5px;
  padding: -5px;
}

.active {
  font-weight: bold;
}

.btn-autos {
  background-color: rgb(0, 38, 189) !important;
  color: white !important;
  width: 90%;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 15px;
}

.btn-autos:hover {
  color: white !important;
}

.btn-autosGray {
  background-color: rgb(232, 232, 232) !important;
  color: rgb(0, 38, 189) !important;
  width: 90%;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 15px;
}

.btn-autosGray:hover {
  color: rgb(0, 38, 189) !important;
}

.a-autos {
  color: rgb(0, 38, 189) !important;
  text-transform: uppercase;
  font-weight: 500;
  text-decoration: none;
}

.a-autosMin {
  color: rgb(0, 38, 189) !important;
  font-weight: 500;
  text-decoration: none;
}

.clearboth {
  clear: both;
}

.containerForm {
  border: 2px solid silver;
  border-radius: 10px;
  padding: 5px;
  margin: 5px;
}

.containerForm .accordion-item {
  border-left: 0px !important;
  border-right: 0px !important;
  border-bottom: 0px !important;
  border-top: 2px solid silver;
}

/* .accordion-button:not(.collapsed) {
  font-weight: 500;
  color: gray;
  background-color: white;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
} */

.select-data-car {
  width: 100%;
  /* margin: 5px !important; */
}

.square-gray{
  background: rgb(232, 232, 232);
  border: 1px solid rgb(232, 232, 232);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 15px;
}


/*General style Radio Button*/

.content-input input[type=radio] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.content-input input[type=radio] {
  visibility: hidden;
  position: absolute;
  right: 0;
}

.content-input {
  position: relative;
  padding: 5px 0 0px 60px;
  /* Damos un padding de 60px para posicionar el elemento <i> en este espacio*/
  display: block;
}

/* Estas reglas se aplicarán a todos las elementos i después de cualquier input*/
.content-input input+i {
  background: #f0f0f0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 0;
  top: 0px;
}

/* Estas reglas se aplicarán a todos los i despues de un input de tipo radio*/
.content-input input[type=radio]+i {
  height: 30px;
  width: 30px;
  border-radius: 100%;
  left: 10px;
}

.content-input input[type=radio]+i:before {
  content: '\2713';
  font-weight: bolder;
  padding: 3px 7px;
  display: block;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
  background: rgb(0, 38, 189);
  color: #f0f0f0;
  transition: all 0.25s ease;
  /* Todas las propiedades | tiempo | tipo movimiento */
  transform: scale(0)
    /* Lo reducimos a 0*/
  ;
  opacity: 0;
  /* Lo ocultamos*/
}

.content-input input[type=radio]:checked+i:before {
  transform: scale(1);
  opacity: 1;
}

.content-input:hover input[type=radio]:not(:checked)+i {
  background: #B1E8CD;
}

/* Form */
.form-control {
  border-radius: 0% !important;
  border: none !important;
  border-bottom: 1px solid silver !important;
  padding: 15px 0;
}

.modal-fullscreen {
  position: absolute !important;
  width: 100vw;
  max-width: none;
  height: 70% !important;
  margin: 0;
  /* top: 220px !important; */
  bottom: 0px !important;
}

/* =========== ANTD CSS ============*/

.ant-select-selector {
  border: none !important;
  border-bottom: 1px solid silver !important;
}

.cp .ant-form-item-control-input-content {
  border: none !important;
  border-bottom: 1px solid silver !important;
}

.ant-btn-primary {
  background: rgb(0, 38, 189) !important;
  border-color: rgb(0, 38, 189) !important;
  width: 90%;
  border-radius: 5px !important;
}

.formulario .ant-radio-button-wrapper {
  margin: 10px;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 5px !important;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  background: rgb(0, 38, 189) !important;
  border-color: rgb(0, 38, 189) !important;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  background: rgb(0, 38, 189) !important;
  border-color: rgb(0, 38, 189) !important;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: rgb(0, 38, 189) !important;
  border-color: rgb(0, 38, 189) !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active::before {
  background-color: none !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
  background-color: none !important;
}

/* Radio button */

.ant-radio-checked .ant-radio-inner{
  border-color: rgb(0, 38, 189) !important ;
}

.ant-radio-checked .ant-radio-inner:after{
  background-color: rgb(0, 38, 189);
}

.ant-radio:hover .ant-radio-inner {
  border-color: rgb(0, 38, 189) ;
}

/* Slick custom */
.slick-slide {
  border: 1px solid silver;
  padding: 5px !important;
  border-radius: 10px !important;
  margin: 1px;
}

.button, .variable-width .slick-slide p{
  background: none !important;
}

.ant-radio-group{
  width: 100% !important;
}

.slick-list{
  height: 530px !important;
}

/* PLaceholder color */

input::placeholder {
  color: gray !important;
}

.ant-select-selection-placeholder {
  color: gray !important;
}

.ant-checkbox .ant-checkbox-inner {
  border-color: gray !important;
}

.ant-select-selector, .ant-select-selector:focus-within {
  border: 1px solid #d9d9d9 !important;
}
